import 'react-quill/dist/quill.snow.css';

import { RemoveCircle } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Snackbar,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import LoadingScreen from '../../../common/loading-screen';
import Axios from '../../../utils/Axios';
import {
  getDevelopers,
  getGenres,
  getLiveGames,
  getPartners,
  getPublishers,
  getStores,
} from '../../../utils/MasterData';

let initialFormState = {
  label: '',
  index_number: '',
  type: '',
  plans: [],
  infos: []
};

const stateData = {
  formState: {},
  live_games: [],
  partners: [],
  modules: [],
  genres: [],
  publisher: [],
  developer: [],
  stores: [],
  age_rating: [],
  formValidationError: {},
  serverError: {
    error: false,
    message: null,
  },
};


export default function PlanCategoriesForm({ mode }) {
  const { plan_categories_id } = useParams();
  const navigate = useNavigate();
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(stateData);
  const [isLoading2, setLoading] = useState(false);
  const [plans, setPlans] = useState([{ name: '', type: '' }]);
  const [infos, setInfos] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);
  const [validationInfoErrors, setValidationInfoErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoLoading, setInfoIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const planCategoriesFormData = values => {
    const errors = {};

    if (!values.label) {
      errors.label = 'Category Title is required';
    }
    else if (values.label.length > 32) {
      errors.label = 'Category Title should be less than 32 characters.';
    }
    // else if (/[^a-zA-Z0-9\s]/.test(values.label)) {
    //   errors.label = 'Category Title should not contain special characters.';
    // }
    if (!values.partner_id) {
      errors.partner_id = 'Partner is required';
    }
    if (!values.type) {
      errors.type = 'Category Type is required';
    }
    if (!values.index_number) {
      errors.index_number = 'Index Number is required';
    }
    else if (values.index_number) {
      // Check if index_number exists
      if (values?.index_number !== undefined) {
        // Validate that index_number is an integer
        if (!Number.isInteger(parseInt(values.index_number))) {
          errors.index_number = 'Index Number must be an integer.';
        }
      } else {
      // Handle the case where index_number is not provided
        errors.index_number = 'Index Number is required';
      }
    }
    if (plans && plans.length == 0) {
      setPlans([...plans, { name: '', type: '' }]);
      let errorPlans = [];
      errorPlans.push({
        name: 'Atleast one Plan Title is required',
      });
      setValidationErrors(errorPlans);
      errors.type = 'Atleast one Plan Title is required';
    }
    else if (plans && plans.length > 0) {

      const errors = plans.map(plan => {
        let error = {};
        if (!plan.name) {
          error.name = 'Plan Title is required';
        }
        else if (plan.name.length > 32) {
          error.name = 'Plan Title should be less than 32 characters';
        }

        if (!plan.type) error.type = 'Plan Type is required';
        return error;
      });

      setValidationErrors(errors);
    }

    if (infos && infos.length > 0) {

      const errors = infos.map(info => {
        let error = {};
        if (!info.label) {
          error.label = 'Info is required';
        }
        else if (info.label.length > 250) {
          error.label = 'Info should be less than 250 characters';
        }


        if (!info.type) error.type = 'Icon Type is required';
        return error;
      });
      setValidationInfoErrors(errors);
    }
    return errors;
  };

  const getMastersData = () => {
    getPartners().then(response => {
      setState(prevStyle => ({
        ...prevStyle,
        partners: response,
      }));
    });

    getLiveGames().then(response => {
      // Get unique age_rating
      let age_ratings = response
        .map(i => i.age_rating)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter(v => v != '' && v != null)
        .map((value, index) => {
          return { id: index + 1, name: value };
        });

      setState(prevStyle => ({
        ...prevStyle,
        live_games: response,
        age_rating: age_ratings,
      }));
    });

    getGenres().then(response => {
      setState(prevStyle => ({
        ...prevStyle,
        genres: response,
      }));
    });

    getPublishers().then(response => {
      setState(prevStyle => ({
        ...prevStyle,
        publisher: response,
      }));
    });

    getDevelopers().then(response => {
      setState(prevStyle => ({
        ...prevStyle,
        developer: response,
      }));
    });

    getStores().then(response => {
      setState(prevStyle => ({
        ...prevStyle,
        stores: response,
      }));
    });
  };

  const handleRemovePlan = (index) => {
    if (plans.length > 1) {
      const updatedPlans = plans.filter((_, i) => i !== index);
      setPlans(updatedPlans);
    }
  };

  const handleRemoveInfo = (index) => {
    if (infos.length >= 1) {
      const updatedInfos = infos.filter((_, i) => i !== index);
      setInfos(updatedInfos);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };


  const getDetails = async () => {
    setLoading(true);
    try {
      let response = await Axios.get(
        'plan-categories/' + plan_categories_id
      );
      setLoading(false);
      if (response.success) {
        let planCategoriesData = Object.assign(
          {},
          response.data.subscription_package_categories
        );
        // console.log('edit planCategoriesData:', planCategoriesData);

        setState(prevStyle => ({
          ...prevStyle,
          formState: planCategoriesData,
        }));

        setState(prevStyle => ({
          ...prevStyle,
          formState: {
            ...prevStyle.formState,
            type : response.data.subscription_package_categories.type
          },
        }));

        let category_plan_names = response.data.subscription_package_categories.category_plan_types.map(item => ({
          name: item.plan_title,
          type: item.plan_type,
        }));

        let category_plan_infos = JSON.parse(response.data.subscription_package_categories.infos);
        // console.log(JSON.parse(category_plan_infos));
        let category_plan_infos_mapped = category_plan_infos.map(item => ({
          label: item.label,
          type: item.type,
        }));
        if(category_plan_names.length > 0)
          setPlans(category_plan_names);
        if(category_plan_infos_mapped.length > 0 && (category_plan_infos_mapped[0].label != null || category_plan_infos_mapped[0].type != null))
          setInfos(category_plan_infos_mapped);

      } else {
        document.documentElement.scrollTo(0, 0);
        setState(prevStyle => ({
          ...prevStyle,
          serverError: {
            error: true,
            message: response.message,
            errors: response.errors,
          },
        }));
      }
    } catch (error) {
      setLoading(false);
      setState(prevStyle => ({
        ...prevStyle,
        serverError: {
          error: true,
          message: 'Server is down',
        },
      }));
    }
  };

  useEffect(() => {
    if (mode == 'Create') {
      stateData.formState = initialFormState;
    }
    getMastersData();
    if (mode == 'Edit') {
      getDetails();
    }
  }, []);

  const handleNavLinkClick = event => {
    if (isEdited) {
      event.preventDefault();
      setShowModal(true);
    }
  };

  const handleConfirmLeave = () => {
    setShowModal(false);
    navigate('/auth/subscription-mgmt/plan-categories/');
  };

  const handleCancelLeave = () => {
    setShowModal(false);
  };

  const handleClearForm = event => {
    event.preventDefault();
    setState(prevStyle => ({
      ...prevStyle,
      formState: initialFormState,
    }));
  };
  ;
  const handlePlanFormChange = (index, event) => {
    const { name, value } = event.target;
    if (name == 'name' || name == 'type') {
      const updatedPlans = [...plans];
      updatedPlans[index][name] = value;
      setPlans(updatedPlans);
    }
  };

  const handleInfoFormChange = (index, event) => {
    const { name, value } = event.target;
    if (name == 'label' || name == 'type') {
      const updatedInfos = [...infos];
      updatedInfos[index][name] = value;
      setInfos(updatedInfos);
    }
  };

  const handleFormChange = (event) => {
    setIsEdited(true);
    if (
      event.target.name === 'partner_ids' ||
      event.target.name == 'game_ids'
    ) {
      const {
        target: { value },
      } = event;
      setState(prevStyle => ({
        ...prevStyle,
        formState: {
          ...prevStyle.formState,
          [event.target.name]:
            typeof value === 'string' ? value.split(',') : value,
        },
      }));
    } else {
      setState(prevStyle => ({
        ...prevStyle,
        formState: {
          ...prevStyle.formState,
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (
      confirm(
        'Are you sure you want to ' +
          (mode == 'Create' ? 'save' : 'update') +
          ' Category Plan Type data?'
      )
    ) {
      doUpdate();
    }
  };

  const handleAddPlan = async event => {
    event.preventDefault();
    const errors = plans.map(plan => {
      let error = {};
      if (!plan.name) {
        error.name = 'Plan Title is required';
      }
      else if (plan.name.length > 32) {
        error.name = 'Plan Title should be less than 32 characters';
      }

      if (!plan.type) error.type = 'Plan Type is required';
      return error;
    });
    setValidationErrors(errors);

    // Check if any field is invalid
    if (errors.some(error => Object.keys(error).length > 0)) {
      return;
    }

    setIsLoading(true);

    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
      // setSuccessMessage('Plan added successfully!');
      setPlans([...plans, { name: '', type: '' }]);
    }, 1000);
  };


  const handleAddInfo = async event => {
    event.preventDefault();
    const errors = infos.map(info => {
      let error = {};
      if (!info.label) {
        error.label = 'Info is required';
      }
      else if (info.label.length > 250) {
        error.label = 'Info should be less than 250 characters';
      }


      if (!info.type) error.type = 'Icon Type is required';
      return error;
    });
    setValidationInfoErrors(errors);

    // Check if any field is invalid
    if (errors.some(error => Object.keys(error).length > 0)) {
      return;
    }

    setInfoIsLoading(true);

    // Simulate an API call
    setTimeout(() => {
      setInfoIsLoading(false);
      // setSuccessMessage('Additional Info added successfully!');
      setInfos([...infos, { label: '', type: '' }]);
    }, 1000);
  };

  const doUpdate = async () => {
    setLoading(true);
    let errors = planCategoriesFormData(state.formState);

    setState(prevStyle => ({
      ...prevStyle,
      formValidationError: errors,
    }));

    if (!Object.keys(errors).length) {
      try {
        let planCategoriesData = Object.assign({}, state.formState);

        planCategoriesData.plans = plans;
        planCategoriesData.infos = infos;
        // console.log('formState:', planCategoriesData);

        let response = null;
        if (mode == 'Create') {
          response = await Axios.post('plan-categories', planCategoriesData);
        }
        if (mode == 'Edit') {
          response = await Axios.put(
            'plan-categories/' + plan_categories_id,
            planCategoriesData
          );
        }

        setLoading(false);
        // console.log('response:', response);
        if (response && response.success) {
          setIsEdited(false);
          window.location.href = '/auth/subscription-mgmt/plan-categories/';
        } else {
          setState(prevStyle => ({
            ...prevStyle,
            serverError: {
              error: true,
              message: response?.message,
              errors: response?.errors,
            },
          }));
        }
      } catch (error) {
        // console.error('handleSubmit Error:', error);
        setLoading(false);
        setState(prevStyle => ({
          ...prevStyle,
          serverError: {
            error: true,
            message: 'Server is down',
          },
        }));
      }
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading2 && <LoadingScreen />}
      <Box mb={3}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <Grid item>
            <Box
              mb={2}
              fontSize="20px"
              fontWeight="700"
              className="themeGradientText"
            >
              Category-Type-Plan / {mode}
            </Box>
          </Grid>
        </Grid>
        <Box className="cardBoxShadow br12" p={2}>
          <Box mb={2}>
            <NavLink
              to="/auth/subscription-mgmt/plan-categories/"
              onClick={handleNavLinkClick}
              className="customColor textTransform text-decor"
            >
              <IconButton className="hoverBtnBg" aria-label="delete">
                <KeyboardBackspaceIcon />
              </IconButton>
            </NavLink>
          </Box>
          {state?.serverError?.error && (
            <Box mb={1}>
              <Alert severity="error">
                {state?.serverError?.message}
                <ul>
                  {state?.serverError?.errors && Object.keys(state?.serverError?.errors).map(e => {
                    return (
                      <>
                        {state.serverError.errors[e].map(em => {
                          return <li key={e + '_' + em}>{em}</li>;
                        })}
                      </>
                    );
                  })}
                </ul>
              </Alert>
            </Box>
          )}
          {/* <Box component="form" onSubmit={handleUpdate} noValidate> */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box className="LabelText">Partner
                <Box component="span" className="redColor">
                  *
                </Box>
              </Box>
              <FormControl fullWidth error={Boolean(state.formValidationError.partner_id)}>
                <Select
                  fullWidth
                  size="small"
                  name="partner_id"
                  onChange={handleFormChange}
                  value={state.formState?.partner_id ?? ''}
                >
                  {state.partners && state.partners.map(partner => (
                    <MenuItem key={partner.id} value={partner.id}>
                      {partner.name}
                    </MenuItem>
                  ))}
                </Select>
                {state.formValidationError.partner_id && (
                  <FormHelperText>{state.formValidationError.partner_id}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box className="LabelText">
                Category Title
                <Box component="span" className="redColor">
                  *
                </Box>
              </Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                onChange={handleFormChange}
                value={state.formState.label || ''}
                name="label"
                error={
                  state.formValidationError.label &&
                  Boolean(state.formValidationError.label)
                }
                helperText={
                  state.formValidationError.label &&
                  state.formValidationError.label
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Box className="LabelText">
              Category Type
                <Box component="span" className="redColor">
                *
                </Box>
              </Box>
              <FormControl fullWidth error={Boolean(state.formValidationError.type)}>
                <Select
                  fullWidth
                  size="small"
                  name="type"
                  onChange={handleFormChange}
                  value={state.formState.type || ''}
                >
                  <MenuItem value="regular">Regular</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
                {state.formValidationError.type && (
                  <FormHelperText>{state.formValidationError.type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box className="LabelText">
                Index Number
                <Box component="span" className="redColor">
                  *
                </Box>
              </Box>
              <TextField
                fullWidth
                type='number'
                size="small"
                variant="outlined"
                onChange={handleFormChange}
                value={state.formState.index_number || ''}
                name="index_number"
                error={
                  state.formValidationError.index_number &&
                  Boolean(state.formValidationError.index_number)
                }
                helperText={
                  state.formValidationError.index_number &&
                  state.formValidationError.index_number
                }
              />
            </Grid>

          </Grid>
          <div>
            <br></br>
            <Divider textAlign="center"><h4>PLAN TYPES</h4></Divider>
            {plans.map((plan, index) => (
              <Grid container spacing={4} key={index} pt={1}>
                <Grid item xs={1}>
                  <h4>{index + 1}. </h4>
                </Grid>
                <Grid item xs={4}>
                  <Box className="LabelText">
              Plan Title
                    <Box component="span" className="redColor">
                *
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(event) => handlePlanFormChange(index, event)}
                    value={plan.name || ''}
                    name="name" // Use the property name directly
                    error={
                      validationErrors[index]?.name &&
                Boolean(validationErrors[index]?.name)
                    }
                    helperText={
                      validationErrors[index]?.name &&
                validationErrors[index]?.name
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box className="LabelText">
              Plan Type
                    <Box component="span" className="redColor">
                *
                    </Box>
                  </Box>
                  <FormControl fullWidth size="small" error={Boolean(validationErrors[index]?.type)}>
                    <Select
                      name="type"
                      onChange={(event) => handlePlanFormChange(index, event)}
                      value={plan.type || ''}
                    >
                      <MenuItem value="topup">Hourly</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="base">Monthly</MenuItem>
                      <MenuItem value="quarterly">Quarterly</MenuItem>
                      <MenuItem value="half_yearly">Half Yearly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                      <MenuItem value="base_nightly">Night Plans</MenuItem>
                    </Select>
                    {validationErrors[index]?.type && (
                      <FormHelperText>{validationErrors[index].type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Box className="LabelText">
              &nbsp;
                  </Box>
                  {index > 0 && (
                    <IconButton onClick={() => handleRemovePlan(index)}>
                      <RemoveCircle />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            {plans.length < 5 && <Box mt={2}>
              <Button
                type="button"
                size="small"
                className="customGradientBtn"
                onClick={handleAddPlan}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Add Plan'}
              </Button>
            </Box>}
            <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </div>
          <div>
            <br></br>
            <Divider textAlign="center"><h4>ADDITIONAL INFO</h4></Divider>
            {infos.map((info, index) => (
              <Grid container spacing={2} key={index} pt={1}>
                <Grid item xs={4}>
                  <Box className="LabelText">
                    Info
                    <Box component="span" className="redColor">
                    *
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(event) => handleInfoFormChange(index, event)}
                    value={info.label || ''}
                    name="label" // Use the property name directly
                    error={
                      validationInfoErrors[index]?.label &&
                Boolean(validationInfoErrors[index]?.label)
                    }
                    helperText={
                      validationInfoErrors[index]?.label &&
                validationInfoErrors[index]?.label
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box className="LabelText">
                    Icon Type
                    <Box component="span" className="redColor">
                    *
                    </Box>
                  </Box>
                  <FormControl fullWidth size="small" error={Boolean(validationInfoErrors[index]?.type)}>
                    <Select
                      name="type"
                      onChange={(event) => handleInfoFormChange(index, event)}
                      value={info.type || ''}
                      displayEmpty
                    >
                      <MenuItem value="DEFAULT">DEFAULT</MenuItem>
                      <MenuItem value="ONESPACE">ONESPACE</MenuItem>
                    </Select>
                    {validationInfoErrors[index]?.type && (
                      <FormHelperText>{validationInfoErrors[index].type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Box className="LabelText">
              &nbsp;
                  </Box>
                  {index >= 0 && (
                    <IconButton onClick={() => handleRemoveInfo(index)}>
                      <RemoveCircle />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            {infos.length < 5 && <Box mt={2}>
              <Button
                type="button"
                size="small"
                className="customGradientBtn"
                onClick={handleAddInfo}
                disabled={isInfoLoading}
              >
                {isInfoLoading ? <CircularProgress size={24} /> : 'Add Info'}
              </Button>
            </Box>}
            <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </div>

          <Box mt={3} textAlign="right">
            <Box component="span" mr={1}>
              <NavLink to="/auth/subscription-mgmt/plan-categories/" className="text-decor">
                <Button
                  size="small"
                  className="mutedBtn"
                  onClick={
                    mode == 'Create' ? handleClearForm : handleNavLinkClick
                  }
                >
                  {mode == 'Create' ? 'Reset' : 'Cancel'}
                </Button>
              </NavLink>
            </Box>
            <Button
              type="submit"
              size="small"
              className="customGradientBtn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
      <Dialog open={showModal}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <p>You have unsaved changes. Are you sure you want to leave?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmLeave}>Leave</Button>
          <Button onClick={handleCancelLeave}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
