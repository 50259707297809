import { saveAs } from 'file-saver';
import moment from 'moment';
import * as UAParser from 'ua-parser-js';

import Axios from './Axios';

const uaParser = new UAParser.UAParser();

export const capitalizeFirst = str => {
  if (str!==undefined) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export function toCamelCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word) {
    return word.toUpperCase();
  }).replace(/\s+/g, ' ');
}
export const camelCaseStr = str => {
  if (str !== undefined) {
    str = str.replace(/_/g, ' ');
    return toCamelCase(str);
  }
  return '-';

};

export const curentLocalTimeYmdHisA = () => {
  return moment().local(true).format('YYYY-MM-DD hh:mm:ss A');
};

export const utctoLocal = datetime => {
  return moment.utc(datetime).local().format('YYYY-MMM-DD h:mm A');
};

export const curentUTCTimeYmdHisA = () => {
  return moment().utc(true).format('YYYY-MM-DD hh:mm:ss A');
};

export const datetimeToDateFormat = datetime => {
  if (!datetime) {
    return '-';
  }
  return moment(datetime).utc(true).local().format('YYYY-MM-DD');
};

export const datetime12HourFormat = datetime => {
  if (!datetime) {
    return '-';
  }
  let dtUtc = moment.utc(datetime).toDate();
  return moment.utc(dtUtc).local().format('YYYY-MM-DD hh:mm:ss A');
};

export const priceFormat = price => {
  return (Math.round(price * 100) / 100).toFixed(2);
};

export const datetimeToDMYdateFormat = datetime => {
  if (!datetime) {
    return '-';
  }
  return moment(datetime).utc(true).format('DD-MM-YYYY');
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
};

export const capitalAll = str => {
  return str.toUpperCase();
};

export const formatToLocalDate = datetime => {
  return moment(datetime).utc(true).format('YYYY-MM-DD hh:mm:ss A');
};

export const unixTime12HourLocalFormat = (datetime, utc = false) => {
  return moment.unix(datetime).utc(utc).format('YYYY-MM-DD hh:mm:ss A');
};

export const unixTime12HourFormat = datetime => {
  return moment.unix(datetime).utc(false).format('YYYY-MM-DD hh:mm:ss A');
};

export const unixTime24HourLocalFormat = (datetime, utc = false) => {
  return moment.unix(datetime).utc(utc).format('YYYY-MM-DD HH:mm:ss');
};

export const unixTimeDiffInMinutesText = datetime => {
  return moment.unix(datetime).format('YYYY-MM-DD hh:mm:ss A');
};

export const diffMinutesToHourMinutes = mins => {

  let time=mins.asMinutes();
  var Hours = Math.floor(time /60);
  var minutes = time % 60;
  Hours = Math.round(Hours);

  let return_text = '';

  if(Hours > 0)
    return_text = Hours <= 1 ? Hours + ' hour' : Hours + ' hours ';

  if (minutes > 0) {
    minutes = Math.round(minutes);
    return_text += minutes <= 1 ? minutes + ' minute' : minutes + ' minutes';
  }
  else
    return_text += minutes <= 1 ? minutes + ' minute' : minutes + ' minutes';

  return return_text;
};

export const minutesToHourMinutes = mins => {

  var Hours = Math.floor(mins /60);
  var minutes = mins % 60;
  Hours = Math.round(Hours);

  let return_text = '';

  if(Hours > 0)
    return_text = Hours <= 1 ? Hours + ' hour ' : Hours + ' hours ';

  if (minutes > 0) {
    minutes = Math.round(minutes);
    return_text += minutes <= 1 ? minutes + ' minute' : minutes + ' minutes';
  }
  else
    return_text += minutes <= 1 ? minutes + ' minute' : minutes + ' minutes';

  return return_text;
};

export const parseUA = uastring => {
  if (uastring) {
    try {
      uaParser.setUA(uastring);
      return {
        os: uaParser.getOS(),
        browser: uaParser.getBrowser(),
        cpu: uaParser.getCPU(),
        device: uaParser.getDevice(),
        engine: uaParser.getEngine(),
      };
    } catch (err) {
      return null;
    }
  }

  return null;
};

export const getBrowserAndVersion = uastring => {
  if (uastring != null && uastring != undefined && typeof uastring != 'undefined') {
    try {
      // Check if its Dart UA
      const dartRegex = /Dart\/*.* \(dart:io\)/gm;
      const onePlayRegex = /OnePlay\/*.*/gm;
      if (dartRegex.test(uastring) || onePlayRegex.test(uastring)) {
        return uastring;
      }
      uaParser.setUA(uastring);
      const browser = uaParser?.getBrowser();
      if (browser && browser != undefined && typeof browser != 'undefined') {
        return browser?.name + (browser?.version ? ' (v' + String(browser?.version) + ')' : '');
      }
      return '-';
    } catch (err) {
      return '-';
    }
  }

  return '-';
};

export const formatSubscriptionPackageName = subscriptionPackage => {

  let total_offered_tokens = Math.round(subscriptionPackage.total_offered_tokens / 60);

  let subscriptionName = '';
  subscriptionName += (total_offered_tokens >= 180 ? 'Unlimited' : total_offered_tokens);
  subscriptionName += subscriptionPackage.plan_name;
  subscriptionName += subscriptionPackage.value;

  let ptype = 'Hourly';
  if (subscriptionPackage.package_type == 'base' && subscriptionPackage.plan_duration_in_days <= 7 ) {
    ptype = 'Weekly';
  }
  if (subscriptionPackage.package_type == 'base' && subscriptionPackage.plan_duration_in_days > 7 && subscriptionPackage.plan_duration_in_days <= 30 ) {
    ptype = 'Monthly';
  }
  if (subscriptionPackage.package_type == 'base' && subscriptionPackage.plan_duration_in_days > 30 && subscriptionPackage.plan_duration_in_days <= 90 ) {
    ptype = 'Quarterly';
  }
  if (subscriptionPackage.package_type == 'base' && subscriptionPackage.plan_duration_in_days > 90 && subscriptionPackage.plan_duration_in_days <= 180 ) {
    ptype = 'Half Yearly';
  }
  if (subscriptionPackage.package_type == 'base_nightly') {
    ptype = 'Night Plan';
  }

  subscriptionName += ' [' + ptype + ']';

  return subscriptionName;
};


export const containsSpecialChar = (TCode) => {
  var format = /[`@#$%^&*_+\-=[\]{};':"\\|<>/?~]/; //dot, commad and spaces only allowed
  return format.test(TCode);
};

export const downloadFile = async (url, params = {}, filetype, filename) => {
  try {
    console.log('downloadFile: ', url, filename);
    params = {
      ...params,
      'download': true,
      'file_type': filetype
    };
    Axios.get(url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      params: params || {},
      responseType: 'blob'
    }).then((response) => {
      if (response?.type != 'application/json') {
        // Download the file
        saveAs(response, filename);
      } else {
        alert('Error downloading file!');
      }
    }).catch((error) => {
      if (
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') != -1
      ) {
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        })
          .then(err => {
            // here your response comes
            console.log(err.response.data);
          });
      };
    });
  } catch (error) {
    console.error('Failed to download file', error);
  }
};

// function s2ab(s) {
//   var buf = new ArrayBuffer(s.length);
//   var view = new Uint8Array(buf);
//   for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
//   return buf;
// }


export const cleanUnderscores = (str) => {
  var newStr = str.replace(/_/g, ' ');
  return capitalizeFirst(newStr);
};

export const mapPlanTypes = (str) => {
  if(str == 'topup')
    return 'Hourly';
  if(str == 'base')
    return 'Monthly';

  return str;

};
